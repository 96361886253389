import { AvailableLanguage } from '../app/shared/models/available-languages.model';

export const environment = {
  production: false,
  mock: false,
  devTools: 'true',
  DebugAppMode: 'Web',
  BaseUri: 'https://velocity-admin-beta.peakup.org',
  FrontEndUri: '',
  FrontEndSSLUri: 'https://velocity-admin-beta.peakup.org',
  TeamsClientId: 'b4b6b83a-a816-486e-aeab-7948adcff7df',
  PeakAuthUri: 'https://auth.peakup.org',
  PeakDataBaseUri: 'https://data.peakup.org',
  PeakDataToken: '38F72C70-5996-41F6-8253-DF48A47BF450',
  GraphUri: 'https://graph.microsoft.com',
  PeakAnalyticsUri: 'https://analytics-dev.peakup.org',
  PeakAnalyticsToken: 'iXETh9LccSDqOSd8PEdEfHn0vpnQroHE',
  PeakAnalyticsProductId: '61C94C89-FD76-4B98-901C-DD2732B57893',
  OneApiConsentUrl: 'https://one.peakup.org/consent/azuread',
  GeoLocationApiKey: 'FaM99hazbuOJEnCKJwrOCh2855YQzzmzR0xCWyVi1N0',
  instagramClientId: '326969285887505',
  firebase: {
    apiKey: 'AIzaSyBU11mIA_72ttPcA2ue4cwZwXLV_v61TSo',
    authDomain: 'velocitypushnotificationsdev.firebaseapp.com',
    databaseURL: 'https://velocitypushnotificationsdev.firebaseio.com',
    projectId: 'velocitypushnotificationsdev',
    storageBucket: 'velocitypushnotificationsdev.appspot.com',
    messagingSenderId: '819128466961',
    appId: '1:819128466961:web:49c798fe2c3af2eabdbbb0',
  },
  LeaveUri: 'https://leave.peakup.org',
  availableLangs: [
    {
      IsoCode: 'en-US',
      IsDefault: false,
      ShortCode: 'EN',
      Label: 'English',
      Notify: 'Language changed to English.',
      Order: 2,
    },
    {
      IsoCode: 'es-ES',
      IsDefault: false,
      ShortCode: 'ES',
      Label: 'Espanol',
      Notify: 'Idioma cambiado a Espanol.',
      Order: 3,
    },
    {
      IsoCode: 'tr-TR',
      IsDefault: false,
      ShortCode: 'TR',
      Label: 'Türkçe',
      Notify: 'Dil Türkçe olarak değiştirildi',
      Order: 1,
    },
    {
      IsoCode: 'pt-PT',
      IsDefault: false,
      ShortCode: 'PT',
      Label: 'Português',
      Notify: 'Idioma alterado para português',
      Order: 4,
    },
  ] as AvailableLanguage[],
};
